import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API, URL_API_V2 } from "src/app/app.api";

@Component({
  selector: "app-log-consulta",
  templateUrl: "./log-consulta.component.html",
  styleUrls: ["./log-consulta.component.css"],
  providers: [ApiServices],
})
export class LogConsultaComponent implements OnInit {
  @Input() dadosUsuario: any;
  @Input() isFutureUser: Boolean;
  logqueryid:string = '';
  logquery:any = {
    
  };
  outputFD:any;
  outputOriginal:any;
  faturas: any[] = [];
  carregandoLogquery: boolean = false;
  public modalRef: BsModalRef;

  constructor(
    private api: ApiServices,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    // this.carregarFaturamento();
    // this.carregarConsultasMaisUsadas();
  }

  public async carregarDetalhes(): Promise<void> {
    this.carregandoLogquery = true;
    
    // const reference = `${this.anoAtual}-${this.mesAtual
    //   .toString()
    //   .padStart(2, "0")}`;
    const logqueryResult = await this.api.get(
      // `${URL_API_V2}/logquery/detail/${this.logqueryid}`
      `${URL_API_V2}/site/log-query/detail/${this.logqueryid}`
    );
    // console.log(logqueryResult);
    this.logquery = logqueryResult.obj;
    
    // const filtered = billing.filter((row) => row.total < 0);
    // const total = filtered
    //   .map((row) => row.total)
    //   .reduce((a, v) => {
    //     return a + v;
    //   });
    // this.totalFaturamento = parseFloat(Math.abs(total).toFixed(2));
    // this.faturas = filtered;
    // this.carregandoFaturas = false;
    this.carregandoLogquery = false;
  }

  public showModalOutputFD(obj:any,  template: TemplateRef<any>):void {
    this.outputFD = obj;
    this.outputOriginal = null;
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  public showModalOutputFornecedor(obj:any,  template: TemplateRef<any>):void {
    this.outputFD = null;
    this.outputOriginal = obj;
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
