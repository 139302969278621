var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { ApiServices } from "src/app/apiServices.service";
import { URL_API_V2 } from "src/app/app.api";
var LogConsultaComponent = /** @class */ (function () {
    function LogConsultaComponent(api, router, modalService) {
        this.api = api;
        this.router = router;
        this.modalService = modalService;
        this.logqueryid = '';
        this.logquery = {};
        this.faturas = [];
        this.carregandoLogquery = false;
    }
    LogConsultaComponent.prototype.ngOnInit = function () {
        // this.carregarFaturamento();
        // this.carregarConsultasMaisUsadas();
    };
    LogConsultaComponent.prototype.carregarDetalhes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var logqueryResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.carregandoLogquery = true;
                        return [4 /*yield*/, this.api.get(
                            // `${URL_API_V2}/logquery/detail/${this.logqueryid}`
                            URL_API_V2 + "/site/log-query/detail/" + this.logqueryid)];
                    case 1:
                        logqueryResult = _a.sent();
                        // console.log(logqueryResult);
                        this.logquery = logqueryResult.obj;
                        // const filtered = billing.filter((row) => row.total < 0);
                        // const total = filtered
                        //   .map((row) => row.total)
                        //   .reduce((a, v) => {
                        //     return a + v;
                        //   });
                        // this.totalFaturamento = parseFloat(Math.abs(total).toFixed(2));
                        // this.faturas = filtered;
                        // this.carregandoFaturas = false;
                        this.carregandoLogquery = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LogConsultaComponent.prototype.showModalOutputFD = function (obj, template) {
        this.outputFD = obj;
        this.outputOriginal = null;
        this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    };
    LogConsultaComponent.prototype.showModalOutputFornecedor = function (obj, template) {
        this.outputFD = null;
        this.outputOriginal = obj;
        this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    };
    return LogConsultaComponent;
}());
export { LogConsultaComponent };
