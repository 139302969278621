<div class="row">

  <div class="col-12">
    <div class="login_form">

      <fieldset>
        <div class="row">
          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <input class="w-100" [(ngModel)]="logqueryid" placeholder="Cole o logquery aqui" />
            </label>
          </div>


          <div class="section col-4" *ngIf="isFutureUser">
            <label class="input">
              <button class="btn btn-info" (click)="carregarDetalhes()" tooltip="Carregar">

                <i class="fab fa-sistrix"></i> Carregar detalhes
              </button>
            </label>
          </div>
        </div>


        <ng-container>
          <div class="row">
            <div class="col-12">
              <div class="login_header bgvermelho">Informações do request</div>
              <table class="table">
                <tbody>
                  <tr>
                    <th width="150">ID</th>
                    <td>{{carregandoLogquery ? 'Carregando...' : logquery._id}}</td>
                    <th width="200">Sucesso</th>
                    <td>
                      <i class="fas fa-ban text-red" *ngIf="logquery.success === false"></i>
                      <i class="fas fa-check text-green" *ngIf="logquery.success === true"></i>
                      {{logquery.success === true ? 'Sim' : logquery.success === false ? 'Não' : '-'}}
                    </td>
                  </tr>
                  <tr>
                    <th>Endpoint</th>
                    <td>{{logquery.endpoint}}</td>
                    <th>Consulta</th>
                    <td>{{logquery.queryCode}}</td>
                  </tr>
                  <tr>
                    <th>Início</th>
                    <td>{{logquery.startAt | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    <th>Fim</th>
                    <td>{{logquery.endAt | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                  </tr>
                  <tr>
                    <th>Input</th>
                    <td>
                      <pre>{{logquery.input | json}}</pre>
                    </td>
                    <th>Tempo</th>
                    <td>{{logquery.time ? logquery.time/1000 + 's' : ''}}</td>
                  </tr>
                  <tr>
                    <th>Status Code</th>
                    <td>{{logquery.statusCode}}</td>
                    <th>Fornecedor Final</th>
                    <td>{{logquery.isCache === true ? 'Cache' : logquery.supplier}}</td>
                  </tr>

                </tbody>
              </table>

              <div class="login_header bgvermelho">Informações do cliente</div>
              <table class="table">
                <tbody>
                  <tr>
                    <th width="150">ID Cliente</th>
                    <td colspan="3">{{logquery.clientId}}</td>
                  </tr>
                  <tr>
                    <th>Nome cliente</th>
                    <td>{{logquery.clientName}}</td>
                    <th width="150">E-mail cliente</th>
                    <td>{{logquery.clientEmail}}</td>
                  </tr>

                  <tr>
                    <th>ID Usuário</th>
                    <td colspan="3">{{logquery.userId}}</td>
                  </tr>
                  <tr>
                    <th>Nome usuário</th>
                    <td>{{logquery.userName}}</td>
                    <th>E-mail usuário</th>
                    <td>{{logquery.userEmail}}</td>
                  </tr>
                </tbody>
              </table>

              <div class="login_header bgvermelho">Insumos usados</div>
              <table class="table">
                <tbody>
                  <tr>
                    <th>ID logRepo</th>
                    <th>Consulta</th>
                    <th>Fornecedor</th>
                    <th>Sucesso</th>
                    <th>Output FD</th>
                    <th>Output Fornecedor</th>
                  </tr>
                  <ng-container *ngIf="logquery && logquery.partials && logquery.partials.length > 0">
                    <ng-container *ngFor="let partial of logquery.partials">
                      <tr>
                        <td>{{partial._id}}</td>
                        <td>{{partial.queryCodePrimary}}</td>
                        <td>{{partial.supplier}}</td>
                        <td>
                          <i class="fas fa-ban text-red" *ngIf="partial.success === false"></i>
                          <i class="fas fa-check text-green" *ngIf="partial.success === true"></i>
                          {{partial.success === true ? 'Sim' : partial.success === false ? 'Não' : '-'}}
                        </td>
                        <td>
                          <button class="btn btn-info" (click)="showModalOutputFD(partial.output, templateOutputFD)" tooltip="Carregar">
                            <i class="fas fa-eye"></i>
                          </button>
                        </td>
                        <td>
                          <button class="btn btn-info" (click)="showModalOutputFornecedor(partial.outputOriginal, templateOutputFornecedor)" tooltip="Carregar">
                            <i class="fas fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>

              <div class="login_header bgvermelho">Resultado</div>
              <table class="table" width="100%">
                <tbody>
                  <tr>
                    <th width="200">Mensagem FD</th>
                    <td>{{logquery.outputMsg}}</td>
                  </tr>
                  <tr>
                    <th>Output FD</th>
                    <td>
                      <pre>{{logquery.output | json}}</pre>
                    </td>
                  </tr>
                  <tr>
                    <th>Output Fornecedor</th>
                    <td>{{logquery.outputOriginal | json}}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </ng-container>
      </fieldset>
    </div>
  </div>
</div>

<ng-template #templateOutputFD>
  <div class="modal-header">
    <h5 class="modal-title">Output FD</h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <pre>{{outputFD | json}}</pre>
  </div>
</ng-template>

<ng-template #templateOutputFornecedor>
  <div class="modal-header">
    <h5 class="modal-title">Output Fornecedor</h5>
  </div>
  <div class="modal-body" style="max-height: 500px; overflow-y: auto">
    <pre>{{outputOriginal | json}}</pre>
  </div>
</ng-template>